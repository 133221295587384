import getTheme from '../style/themes/theme';
import CryptoJS from 'crypto-js';
import { templates } from '../types/Module';
import _ from 'lodash';
import { contentSourceTypes } from '../types/Item';
import { ObjectType, ObjectTypes } from '../types/Object';
import SVGInline from 'react-inlinesvg';
import icons from '../style';
import React from 'react';
import { renderTooltip, tooltipTypes } from '../components/common/Tooltips/Tooltips';

export const API_ERROR_STATUSES = [400, 401, 404, 500];
export const API_ERROR_CODES = {
    AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
    AUTHENTICATION_ERROR_2FA_REQUIRED: 'AUTHENTICATION_ERROR_2FA_REQUIRED',
    DATA_VALIDATION_ERROR: 'DATA_VALIDATION_ERROR',
    DATABASE_ERROR: 'DATABASE_ERROR',
    DISABLED_ERROR: 'DISABLED_ERROR',
    AUDIENCE_VALIDATION_ERROR_SIMILAR: 'AUDIENCE_VALIDATION_ERROR_SIMILAR',
    AUDIENCE_VALIDATION_ERROR: 'AUDIENCE_VALIDATION_ERROR',
    AUDIENCE_VALIDATION_ERROR_SIMILAR_TEMPLATE: 'AUDIENCE_VALIDATION_ERROR_SIMILAR_TEMPLATE',
    DATA_VALIDATION_ERROR_PHONE_NUMBER: 'DATA_VALIDATION_ERROR_PHONE_NUMBER',
    AUTHENTICATION_ERROR_PHONE_NUMBER_NOT_SET: 'AUTHENTICATION_ERROR_PHONE_NUMBER_NOT_SET',
    AUTHENTIFICATION_ERROR_OTP_NOT_VALID: 'AUTHENTIFICATION_ERROR_OTP_NOT_VALID',
    PUBLISHED_REFERENCE_OBJECT: 'PUBLISHED_REFERENCE_OBJECT',
    LOCKED_ERROR: 'LOCKED_ERROR',
    ADMIN_LOCKED_ERROR: 'ADMIN_LOCKED_ERROR',
    ALREADY_MODIFIED: 'ALREADY_MODIFIED_ERROR',
    UNAUTHORIZED_USER_UPDATE: 'UNAUTHORIZED_USER_UPDATE',
    REFERENCE_OBJECT: 'REFERENCE_OBJECT'
};
export const API_ERROR_MESSAGES = {
    RESOURCE_NOT_FOUND_FOR_ID: 'We did not find a resource for a provided id in the database or the resource belongs to another project.'
};

export const emailRegex = /\S+@\S+\.\S+/;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;

export const imgixDefaultQuery = 'auto=format,enhance&q=60';

export const awsS3BaseUrl = 'https://s3.eu-central-1.amazonaws.com';

export const SECRET_HEALTHCHECK_URL = '_healthcheck';
export const SECRET_WEBUI_ACCESS_KEY = CryptoJS.MD5('_webuisecret').toString();

export const DEMO_TENANT_ID = 'd458b2e8-e4c0-484b-a482-c7e75ca884be';
export const DEVELOPMENT_TENANT_ID = '05184600-d9a8-4086-803b-fda6f917fbf1';
export const FORD_TENANT_ID = 'a20cb758-7951-48ef-8c5a-5bfdaf4bccbf';

export const getImgixUrl = (imgixBaseUrl: string, fileName: string, query = '', isFileManager?: boolean) => {
    // In order to show gifs properly, we do not add any imgix query, we just simply return the url
    if (_.last(fileName.split('.')) === 'gif') {
        return `${imgixBaseUrl}${fileName}`;
    }
    return `${imgixBaseUrl}${fileName}?` + (query || imgixDefaultQuery);
};

export const getVideoCdnUrl = (videoCdnUrl: string, fileName: string) => {
    return `${videoCdnUrl}${fileName}`;
};

const theme: any = getTheme();
export const BUTTON_COLORS_MAP = new Map<
    string,
    { color: string; hover: string; pressed?: string; textColor: string; textColorHover?: string }
>([
    [
        'BLUE',
        {
            color: theme.palette.primary.main,
            hover: theme.palette.primary.focused,
            pressed: theme.palette.primary.pressed,
            textColor: theme.palette.white.main
        }
    ],
    ['RED', { color: theme.palette.error.main, hover: theme.palette.error.focused, textColor: theme.palette.white.main }],
    [
        'DEFAULT',
        {
            color: 'transparent',
            hover: theme.palette.text.main,
            textColor: theme.palette.text.main,
            textColorHover: theme.palette.white.main
        }
    ],
    [
        'WHITE',
        {
            color: theme.palette.white.main,
            hover: theme.palette.text.main,
            textColor: theme.palette.text.main,
            textColorHover: theme.palette.white.main
        }
    ]
]);

export const DRAGGABLE_TYPES = {
    PAGE: 'page',
    CONFIGURATION: 'configuration',
    MODULE: 'module',
    ITEM: 'item',
    LANGUAGE: 'language',
    MENU_ITEM: 'menu_item'
};

export const FILE_MANAGER_DRAGGABLE_TYPES = {
    FILE: 'FILE',
    FOLDER: 'FOLDER'
};

export const USERROLES = { SUPER_ADMIN: 'superAdmin', VIEWER: 'Viewer', EDITOR: 'Editor' };

export enum moduleTypes {
    STRIPE = 'stripe',
    COLLECTION = 'collection',
    EPG = 'epg'
}

export const allowedTemplatesForCollection = [
    templates['1X1_ROUNDED'],
    templates['1X1_SQUARED'],
    templates['2X3'],
    templates['16X9_M'],
    templates['16X9_S']
];

export enum moduleTemplates {
    GALLERY = 1,
    PREVIEW,
    '16X9_M',
    '16X9_S',
    '2X3',
    '1X1_SQUARED',
    '1X1_ROUNDED',
    BANNER_XXL,
    BANNER_XL,
    BANNER_L,
    BANNER_M,
    BANNER_S,
    BUTTON
}

export const platformAssetstoreServices: string[] = [
    contentSourceTypes['3READY_ASSETS'],
    contentSourceTypes.PRIME_VIDEO,
    contentSourceTypes.DAZN,
    contentSourceTypes.DISNEY_PLUS,
    contentSourceTypes.MOTOR_RACING,
    contentSourceTypes.SWR3,
    contentSourceTypes.TED_TALKS
];

export const EMPTY_WORD_STRING = '[EMPTY]';
export const DROPDOWN_GROUP_LABEL_VALUE = 'group_label';
export const DROPDOWN_ADD_VALUE = 'add_new';
export const DROPDOWN_SELECT_ALL_VALUE = 'select_all';
export const SUPERADMIN_PREVIEW_PROJECT_ID_PARAM = '[SUPERADMIN]';

export const getIconByObjectType = (objectType: ObjectType) => {
    switch (objectType) {
        case ObjectTypes.PAGES:
            return renderTooltip(<SVGInline src={icons.pagesIcon} />, tooltipTypes.HTML, 'Page');
        case ObjectTypes.MODULES:
            return renderTooltip(<SVGInline src={icons.modulesIcon} />, tooltipTypes.HTML, 'Module');
        case ObjectTypes.ITEMS:
            return renderTooltip(<SVGInline src={icons.itemsIcon} />, tooltipTypes.HTML, 'Item');
        case ObjectTypes.MENUS:
            return renderTooltip(<SVGInline src={icons.menusIcon} />, tooltipTypes.HTML, 'Menu');
        case ObjectTypes.SETTINGS:
            return renderTooltip(<SVGInline src={icons.settingsIcon} />, tooltipTypes.HTML, 'Setting');
        case ObjectTypes.DYNAMIC_SOURCES:
            return renderTooltip(<SVGInline src={icons.dynamicSourcesIcon} />, tooltipTypes.HTML, 'Dynamic Source');
        case ObjectTypes.FEATURE_FLAGS:
            return renderTooltip(<SVGInline src={icons.featureFlagIcon} />, tooltipTypes.HTML, 'Feature Flags');
        case ObjectTypes.PAGE_STYLES:
            return renderTooltip(<SVGInline src={icons.styleAndBrandingIcon} />, tooltipTypes.HTML, 'Page Style');
        default:
            return null;
    }
};
