import { dialogAlert } from './fnDialogs';
import icons from '../assets/images/icons';
import { FailedDeletionMessages } from '../types/Object';
import { EMPTY_WORD_STRING } from './Globals';
import styled from 'styled-components';

const FailedDeletionEntriesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
export const renderFailedObjectDeletions = (failedDeletions: FailedDeletionMessages) => {
    const showIndex = failedDeletions.length > 1;
    const values = {
        title: 'Some of the objects were not deleted!',
        text: (
            <FailedDeletionEntriesWrapper>
                {failedDeletions.map((entry, index) => (
                    <div key={JSON.stringify(entry)}>
                        {showIndex && `${index + 1}.`} The <strong>{entry.objectType.slice(0, -1)}</strong> with the name{' '}
                        <strong>{entry.objectName || EMPTY_WORD_STRING}</strong> was not deleted because {entry.message}
                    </div>
                ))}
            </FailedDeletionEntriesWrapper>
        )
    };

    dialogAlert('', false, values, null, false, icons.warningYellowIcon, null, true);
};
