import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ADTypes, AdSetting } from '../../types/AdSettings';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';

export const fetchAdSettings = createAsyncThunk<
    { adSettings: AdSetting[]; error: { message: string; code: string } | null; totalResults?: number },
    {
        projectId: string;
        type: ADTypes;
        pageSize?: number;
        pageNumber?: number;
        orderBy?: string;
        searchTerm?: string;
    }
>('adSettings/fetchAdSettings', async ({ projectId, type, pageSize, pageNumber, orderBy, searchTerm }, thunkApi) => {
    const result = await ConfigServiceAPI.getAdSettings(projectId, type, pageSize, pageNumber, orderBy, searchTerm);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { adSettings: result.response as any, error: null, totalResults: result.totalResults };
});

export const fetchAdUnits = createAsyncThunk<{
    adUnits: { id: string; name: string; adUnitCode: string; networkCode: string }[];
    error: { message: string; code: string } | null;
}>('adSettings/fetchAdUnits', async (_, thunkApi) => {
    const result = await ConfigServiceAPI.getAdUnits();

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { adUnits: result.response as any, error: null };
});

export const createAdSettings = createAsyncThunk<
    { id: string; error: { message: string; code: string } | null; permissions?: any },
    AdSetting
>('adSettings/createAdSettings', async (adSettings, thunkApi) => {
    const result = await ConfigServiceAPI.createAdSettings(adSettings);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { id: result.response as any, error: null };
});

export const updateAdSettings = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null; permissions?: any },
    AdSetting
>('adSettings/updateAdSettings', async (adSettings, thunkApi) => {
    const result = await ConfigServiceAPI.updateAdSettings(adSettings);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response as any, error: null };
});

export const deleteAdSettings = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null; permissions?: any },
    { id: string }
>('adSettings/deleteAdSettings', async ({ id }, thunkApi) => {
    const result = await ConfigServiceAPI.deleteAdSettings(id);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response as any, error: null };
});

export const deleteAdSettingsList = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string[]>(
    'adSettings/deleteAdSettingsList',
    async (items, thunkApi) => {
        const result = await ConfigServiceAPI.deleteAdSettingsList(items);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }

        return { ok: !!result.response, error: null };
    }
);

export interface AdSettingsState {
    adSettings: AdSetting[];
    adUnits: { id: string; name: string; adUnitCode: string; networkCode: string }[];
    loading: boolean;
    error: {
        message: string;
        code?: string;
    } | null;
    totalResults?: number;
}

const initialState: AdSettingsState = {
    adSettings: [],
    adUnits: [],
    error: null,
    loading: false
};

const slice = createSlice({
    name: 'adSettings',
    initialState,
    reducers: {
        unsetAnalyticsError(state) {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAdSettings.fulfilled, (state, action) => {
                state.adSettings = action.payload.adSettings || [];
                state.error = null;
                state.loading = false;
                state.totalResults = action.payload.totalResults;
            })
            .addCase(fetchAdSettings.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.adSettings = [];
                state.loading = false;
            })
            .addCase(fetchAdSettings.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchAdUnits.fulfilled, (state, action) => {
                state.adUnits = action.payload.adUnits || [];
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchAdUnits.rejected, (state, action: any) => {
                state.error = {
                    ...action.payload.error,
                    status: action.payload.status
                };
                state.loading = false;
            })
            .addCase(fetchAdUnits.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createAdSettings.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(createAdSettings.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(createAdSettings.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(updateAdSettings.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(updateAdSettings.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(updateAdSettings.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteAdSettings.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteAdSettings.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteAdSettings.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteAdSettingsList.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteAdSettingsList.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteAdSettingsList.pending, (state, _action) => {
                state.loading = true;
            });
    }
});
export default slice.reducer;
