import styled, { css } from 'styled-components';
import getTheme, { fontSizesCSS } from '../../style/themes/theme';
import Button from '../Buttons/Button/Button';
import { ButtonInner } from '../Buttons/Button/Button.css';

const theme: any = getTheme();

export const ContentProvidersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ContentProvidersTitle = styled.div`
    ${fontSizesCSS.Body2};
`;

export const ContentProviderCardWrapper = styled.div<{ $isDisabled?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 274px;
    box-sizing: border-box;
    gap: 24px;
    padding: 16px;
    border-radius: 12px;
    background: ${theme.palette.white.main};
    ${(props) =>
        props.$isDisabled &&
        css`
            opacity: 0.7;
            pointer-events: none;
        `}
`;

export const ContentProviderStatus = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
        height: 24px;
        width: 24px;
        path {
            fill: ${theme.palette.success.semantic};
        }
    }
`;

export const ContentProviderStatusContainer = styled.div`
    height: 24px;
    display: flex;
    align-items: center;
    ${fontSizesCSS.Caption1};
`;

export const ContentProviderButton = styled(Button)`
    width: 100%;
    ${ButtonInner} {
        text-transform: unset;
    }
`;

export const ContentProviderButtonsWrapper = styled.div`
    width: 100%;
    ${ContentProviderButton} {
        margin: 5px 0;
    }
`;

export const ContentProviderMetadata = styled.div`
    text-align: center;
`;
export const ContentProviderMetadataSubtitle = styled.div`
    ${fontSizesCSS.Caption3};
    opacity: 0.8;
`;
export const ContentProviderMetadataTitle = styled.div``;

export const ContentProviderCards = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;

export const SettingsContainer = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .MuiInputBase-root {
        .MuiInputAdornment-positionEnd {
            margin-right: 8px;
            cursor: pointer;
        }
    }
`;

export const YoutubeSettingsTitle = styled.div`
    ${fontSizesCSS.Body2};
`;

export const YoutubeSettingsToggles = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ContentProviderLogoContainer = styled.div<{ $backgroundColor?: string; disabled?: boolean }>`
    height: 136px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    ${(props) => props.disabled && 'opacity: 0.6'};
    ${({ $backgroundColor }) => $backgroundColor && `background-color: ${$backgroundColor};`}
    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

export const DaznImgSettingsContainer = styled.div`
    width: 144px;
    height: 144px;
    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

export const DisneyImgSettingsContainer = styled.div`
    height: 120px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
`;
