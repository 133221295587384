import React, { FC, useState } from 'react';
import { ApplicationWrapper, MainContentWrapper } from '../../style/styled-components/reusable.css';
import Sidebar from '../common/Sidebar/Sidebar';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import { ContentProviderCards } from '../Aggregate/Aggregate.css';
import { ContentProviderCard, ContentProviderTypes, serviceStates } from '../Aggregate/ContentProvider';
import { useNavigate } from 'react-router-dom';
import { PageRoutes, buildPathWithProjectId } from '../../types/RouteTypes';
import { ActiveItemState } from '../../redux/slices/activeItemSlice';
import { useAppSelector } from '../../hooks/redux';
import { ProviderSettingsDialog } from '../Aggregate/Dialogs/ProviderSettings';
import { Capabilities, CapabilitiesScreen } from '../Capabilities/CapabilitiesTopScreen';
import useRestrictedProjectAccess from '../../hooks/useRestrictedAccess';
import { restrictedSectionsTypes } from '../../types/Project';
import { ProjectsState } from '../../redux/slices/projectsSlice';

const supportedAds = [
    { key: 'google', status: serviceStates.AVAILABLE },
    { key: 'magnite', status: serviceStates.DISABLED },
    { key: 'forScreen', status: serviceStates.DISABLED },
    { key: 'amagi', status: serviceStates.DISABLED }
];

const Monetize: FC = () => {
    const { activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { isRestrictedArea: isMonetizeRestricted } = useRestrictedProjectAccess(restrictedSectionsTypes.CAPABILITIES, 'monetize');
    const { projects }: ProjectsState = useAppSelector((state) => state.projects);
    const navigate = useNavigate();

    const [showSettingsDialog, setShowSettingsDialog] = useState<boolean>(false);
    const [selectedService, setSelectedService] = useState<string>('');
    return (
        <ApplicationWrapper>
            <Sidebar />
            <MainContentWrapper>
                <ScreenTitle loading={false} title={'Monetize'} withProfile />
                <CapabilitiesScreen type={Capabilities.MONETIZE} disabled={isMonetizeRestricted} />

                {!isMonetizeRestricted && !!projects?.length && (
                    <ContentProviderCards>
                        {supportedAds.map((ad) => {
                            return (
                                <ContentProviderCard
                                    key={ad.key}
                                    type={ContentProviderTypes.AD}
                                    providerState={ad.status}
                                    serviceKey={ad.key}
                                    onSettingsClick={() => {
                                        navigate(buildPathWithProjectId(activeProjectId, PageRoutes.AD_RULES));
                                    }}
                                    onPrimaryButtonClick={() => {
                                        if (ad.status === serviceStates.ENABLED) {
                                            setSelectedService(ad.key);
                                            setShowSettingsDialog(true);
                                            return;
                                        }

                                        //TODO: implement connection to Gam
                                    }}
                                />
                            );
                        })}
                    </ContentProviderCards>
                )}

                <ProviderSettingsDialog
                    open={showSettingsDialog}
                    onSave={() => setShowSettingsDialog(false)}
                    onClose={() => setShowSettingsDialog(false)}
                    selectedService={selectedService}
                />
            </MainContentWrapper>
        </ApplicationWrapper>
    );
};

export default Monetize;
