import React, { FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import SVGInline from 'react-inlinesvg';
import {
    DAZNParamNames,
    Service,
    ServiceParamOptionLabels,
    SortOptionsMap,
    StreamingRightsParamOptionLabels
} from '../../../utils/Services';
import { DialogDropdownMultiple, DialogDropdownSingle, DialogTextField } from '../../common/Dialog/GenericDialog';
import configServiceAPI from '../../../utils/api/configServiceAPI';
import { PreviewItemsState } from '../../../redux/slices/previewItemsSlice';
import { useAppSelector } from '../../../hooks/redux';
import { removeEmptyParams } from '../../../utils/fnData';
import icons from '../../../assets/images/icons';
import { OptionSection, OptionsToggle } from '../../Modules/Dialogs/NewModule.css';
import usePrevious from '../../../hooks/usePrevious';
import { contentSourceTypes } from '../../../types/Item';

type DaznSourceFieldsProps = {
    service: Service;
    fieldValues: {
        selectedMethod: string;
        bodyParamsValues: any;
        pathParamsValues: any;
        errors: { method?: string; name?: string; param?: string; bodyParams?: any; pathParams?: any };
    };
    fieldSetters: {
        setSelectedMethod: SetStateAction<any>;
        setErrors: SetStateAction<any>;
        setBodyParamsValues: SetStateAction<any>;
        setQueryParamsValues: SetStateAction<any>;
        setPathParamsValues: SetStateAction<any>;
    };
    dropdownOptions: { methodOptions: { value: string; label: string }[] };
};

export const DaznMethods = {
    dazn_search: {
        name: 'Dazn Video Search',
        filters: [
            'id',
            'providerMetadata.dazn.streamingRights',
            'localization.<streaming_right>.availabilityType',
            'localization.<streaming_right>.sports.id',
            'localization.<streaming_right>.tournaments.id'
        ],
        sort: true,
        hideFilters: true
    },
    dazn_filter: {
        name: 'Dazn Video Filter',
        filters: [
            'providerMetadata.dazn.streamingRights',
            'localization.<streaming_right>.availabilityType',
            'localization.<streaming_right>.sports.id',
            'localization.<streaming_right>.tournaments.id'
        ],
        sort: true,
        hideFilters: false
    }
};

export const DaznMethodKeys = {
    DAZN_SEARCH: 'dazn_search',
    DAZN_FILTER: 'dazn_filter'
};

export const streamingRightsFilterKey = 'filter.providerMetadata.dazn.streamingRights';
const streamingRightsPlaceholder = '<streaming_right>';
const daznDefaultAssetType = 'dynamicEvent';

const DaznSourceFields: FC<DaznSourceFieldsProps> = ({
    service,
    dropdownOptions: { methodOptions },
    fieldValues: { selectedMethod, bodyParamsValues, pathParamsValues, errors },
    fieldSetters: { setSelectedMethod, setBodyParamsValues, setQueryParamsValues, setPathParamsValues, setErrors }
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchOptions, setSearchOptions] = useState<{ value: string; label: string }[]>([]);
    const [showFiltersAlways, setShowFiltersAlways] = useState(false);

    const { items }: PreviewItemsState = useAppSelector((state) => state.preview);
    const selectedStreamingRights = bodyParamsValues[streamingRightsFilterKey];

    const previousStreamingRights = usePrevious(selectedStreamingRights);

    const isStreamingRightsDependantKey = (key: string) => key.includes(streamingRightsPlaceholder);

    useEffect(() => {
        setSearchTerm('');
        setSearchOptions([]);
    }, [selectedMethod]);

    useEffect(() => {
        if (searchTerm.length >= 2) {
            handleSearch(searchTerm, removeEmptyParams(bodyParamsValues), {});
        } else {
            handleSearch.cancel();
            if (!searchTerm) {
                const selectedIds = [...(bodyParamsValues?.['filter.id'] || [])].map((id: string) => decodeURIComponent(id));
                setSearchOptions(searchOptions.filter((opt) => selectedIds.includes(decodeURIComponent(opt.value))));
                setSearchTerm('');
            }
        }
    }, [searchTerm, bodyParamsValues]);

    useEffect(() => {
        if (!items.length || !bodyParamsValues?.['filter.id']?.length || searchOptions.length === items.length || !!searchTerm) return;

        const ids = [...bodyParamsValues['filter.id']];
        setSearchOptions(
            ids
                .map((data: string) => ({
                    value: encodeURIComponent(data),
                    label: items.find((i) => i.id === data)?.title || ''
                }))
                .filter((item) => item.label)
        );
    }, [items]);

    useEffect(() => {
        const newBodyParams = { ...bodyParamsValues };
        let setState = false;

        if (!bodyParamsValues.sort) {
            const defaultSort = service.methods.find((method) => method.key === selectedMethod)?.params.body?.sort?.[0];
            if (defaultSort) {
                setBodyParamsValues((values: any) => ({ ...values, sort: defaultSort }));
            }
        }

        if (bodyParamsValues?.['languages']?.length) {
            // we must auto assign the default asset type for DAZN
            if (!pathParamsValues?.['type']) {
                setPathParamsValues({
                    ...(pathParamsValues || {}),
                    type: daznDefaultAssetType
                });
            }

            // in case of search method, the filters (besides the selected ids) are deleted but in the edit dialog we need them to be assigned
            if (!bodyParamsValues[streamingRightsFilterKey]) {
                newBodyParams[streamingRightsFilterKey] = bodyParamsValues['languages'][0];
                setState = true;
            }
        }

        if (selectedStreamingRights) {
            // the dependant keys must be updated
            Object.keys(bodyParamsValues || {})
                .filter((key) => isStreamingRightsDependantKey(key))
                .forEach((key) => {
                    const newKey = key.replace(streamingRightsPlaceholder, selectedStreamingRights);
                    newBodyParams[newKey] = newBodyParams[key];
                    delete newBodyParams[key];
                    setState = true;
                });
        }

        if (selectedStreamingRights && previousStreamingRights && selectedStreamingRights !== previousStreamingRights) {
            // the dependant keys must be updated
            Object.keys(bodyParamsValues || {})
                .filter((key) => key.includes(previousStreamingRights))
                .forEach((key) => {
                    const newKey = key.replace(previousStreamingRights, selectedStreamingRights);
                    newBodyParams[newKey] = newBodyParams[key];
                    delete newBodyParams[key];
                    setState = true;
                });
        }

        setState && setBodyParamsValues(newBodyParams);
    }, [bodyParamsValues]);

    const handleSearch = useMemo(
        () =>
            _.debounce(async (searchTerm: string, bodyParamsValues, pathParamsValues) => {
                setSearchLoading(true);
                let result: any;
                try {
                    const streamingRights = bodyParamsValues['filter.providerMetadata.dazn.streamingRights'];
                    result = await configServiceAPI.assetstoreSearch(
                        contentSourceTypes.DAZN,
                        daznDefaultAssetType,
                        bodyParamsValues.languages,
                        searchTerm,
                        undefined,
                        undefined,
                        undefined,
                        streamingRights,
                        bodyParamsValues[`filter.localization.${streamingRights}.availabilityType`],
                        bodyParamsValues[`filter.localization.${streamingRights}.sports.id`],
                        bodyParamsValues[`filter.localization.${streamingRights}.tournaments.id`]
                    );
                } catch (ex) {
                    console.error(ex);
                }
                setSearchLoading(false);
                if (!result?.response) return;

                setSearchOptions((searchOptions) => {
                    const newResults = result.response.map((data: any) => ({ value: encodeURIComponent(data.id), label: data.title }));
                    const decodedSelectedValues = bodyParamsValues?.['filter.id']?.length
                        ? bodyParamsValues['filter.id'].map((elem: any) => decodeURIComponent(elem))
                        : [];
                    const allValues = [
                        ...searchOptions.filter((opt: any) => decodedSelectedValues.includes(decodeURIComponent(opt.value))),
                        ...newResults
                    ];

                    return [...new Map(allValues.map((elem) => [elem.value, elem])).values()];
                });
            }, 1000),
        []
    );

    const renderStreamingRightsSelect = () => {
        const options = service.methods[0].params.body?.[streamingRightsFilterKey]?.map((langCode: string) => {
            return {
                value: langCode,
                label: _.get(StreamingRightsParamOptionLabels, langCode, langCode)
            };
        });

        const filter = streamingRightsFilterKey.replace('filter.', '');

        return (
            <DialogDropdownSingle
                key={`streaming-rights-field`}
                value={options.find((opt: any) => bodyParamsValues?.[streamingRightsFilterKey] === opt.value) || ''}
                options={options}
                placeholder={`Select ${_.get(DAZNParamNames, filter)}`}
                labelText={`Select ${_.get(DAZNParamNames, filter, filter)}`}
                error={errors.bodyParams?.[streamingRightsFilterKey]}
                onChange={(value: any) => {
                    const newValue = value.value;
                    if (newValue) {
                        setBodyParamsValues((values: any) => ({ ...values, [streamingRightsFilterKey]: newValue, languages: [newValue] }));
                        setErrors({
                            ...errors,
                            bodyParams: _.omit(errors.bodyParams, [streamingRightsFilterKey, 'filter.id'])
                        });
                    } else {
                        setBodyParamsValues({});
                    }
                }}
                clearable
            />
        );
    };

    const renderSortField = () => {
        if (!_.get(DaznMethods, selectedMethod)?.sort) {
            return <></>;
        }
        const sortOptions = service.methods
            .find((method) => method.key === selectedMethod)
            ?.params.body?.sort?.map((sortString: string) => ({
                value: sortString,
                label: _.get(SortOptionsMap, sortString, sortString)
            }));

        return (
            <DialogDropdownSingle
                value={sortOptions?.find((opt: any) => opt.value === bodyParamsValues?.sort)}
                options={sortOptions}
                placeholder={'Select Sorting'}
                onChange={(selectedSortOpt: any) => {
                    setErrors(_.omit(errors, ['bodyParams', 'sort']));
                    setQueryParamsValues((values: any) => _.omit(values, 'orderBy'));
                    setBodyParamsValues((values: any) => ({ ...values, sort: selectedSortOpt.value }));
                }}
                error={errors.bodyParams?.sort}
                dataCy={'dazn-method-sort-field'}
            />
        );
    };

    const renderFilterFields = () => {
        const methodFilters: string[] = _.get(DaznMethods, [selectedMethod, 'filters'], []);
        const methodParams = service.methods.find((m) => m.key === selectedMethod)?.params?.body;
        if (!methodFilters?.length || !methodParams) return null;

        const fields: JSX.Element[] = [];

        const renderSearchFields = (filter: string, key: string) => {
            const searchFields: JSX.Element[] = [];
            if (filter !== 'id') return searchFields; // to be extended when more search fields will be added

            searchFields.push(
                <DialogTextField
                    key={`search-${filter}-field`}
                    value={searchTerm}
                    label={`Search assets by title`}
                    placeholder={`Insert title`}
                    toolTipText={`Please insert at least 2 characters`}
                    onChange={(evt: any) => {
                        setSearchTerm(evt.target.value);
                    }}
                />
            );

            const decodedValues = bodyParamsValues?.[key]?.length ? bodyParamsValues[key].map((elem: any) => decodeURIComponent(elem)) : [];

            searchFields.push(
                <DialogDropdownMultiple
                    key={`search-options-field`}
                    value={searchOptions.filter((opt: any) => decodedValues.includes(decodeURIComponent(opt.value)))}
                    options={searchOptions}
                    placeholder={searchLoading ? 'Loading...' : `Select ${_.get(DAZNParamNames, filter, filter)}`}
                    labelText={`Select ${_.get(DAZNParamNames, filter, filter)}`}
                    toolTipText={'Use the field above to search assets. The results will appear here.'}
                    onChange={(value: any) => {
                        const newValue = value.map((elem: any) => elem.value);
                        if (key === 'filter.id' && newValue?.length > 1 && bodyParamsValues.sort === 'notSorted') {
                            setQueryParamsValues((values: any) => ({
                                ...values,
                                orderBy: newValue.join(',')
                            }));
                        }
                        setBodyParamsValues((values: any) => ({ ...values, [key]: newValue }));
                        setErrors((errors: any) => {
                            const newErrors = { ...errors };
                            delete newErrors.bodyParams?.['filter.id'];
                            return newErrors;
                        });
                    }}
                    allowSelectAll
                    notSorted
                    withLoadingIndicator={searchLoading}
                    error={errors?.bodyParams?.['filter.id']}
                />
            );

            return searchFields;
        };

        const renderSelectFields = (
            filterObj: any,
            filter: string,
            key: string,
            index: number,
            allFields?: any[],
            hideFilters?: boolean
        ) => {
            const arrayFields: JSX.Element[] = [...renderSearchFields(filter, key)];
            // if we have search fields already or if we need to hide the filters, we move on
            if (arrayFields.length || hideFilters) {
                arrayFields.push(renderShowFiltersToggle(allFields));
                return arrayFields;
            }

            if (key === streamingRightsFilterKey) {
                return arrayFields;
            }

            const options = filterObj.map((elem: any) => ({
                value: elem,
                label: _.get(ServiceParamOptionLabels, elem, _.capitalize(elem))
            }));

            arrayFields.push(
                <DialogDropdownSingle
                    key={`array-field-${index}`}
                    value={options?.find(
                        (opt: any) => bodyParamsValues[key.replace(streamingRightsPlaceholder, selectedStreamingRights)] === opt.value
                    )}
                    options={options}
                    placeholder={`Select ${_.get(DAZNParamNames, filter)}`}
                    labelText={`Select ${_.get(DAZNParamNames, filter, filter)}`}
                    onChange={(value: any) => {
                        const newValue = value.value;
                        if (newValue) {
                            setBodyParamsValues((values: any) => ({ ...values, [key]: newValue }));
                        } else {
                            setBodyParamsValues(_.omit(bodyParamsValues, key.replace(streamingRightsPlaceholder, selectedStreamingRights)));
                        }
                    }}
                    toolTipText={selectedMethod === DaznMethodKeys.DAZN_SEARCH ? 'Just a helper field for the title search' : undefined}
                    clearable
                />
            );

            return arrayFields;
        };

        const renderShowFiltersToggle = (allFields?: JSX.Element[]) => {
            // if we don't need to hide the filters or we already have an options section, we move on
            if (!_.get(DaznMethods, selectedMethod)?.hideFilters || allFields?.find((el) => el.key === 'options-section')) return <></>;

            return (
                <OptionSection key={'options-section'}>
                    <OptionsToggle onClick={() => setShowFiltersAlways(!showFiltersAlways)}>
                        {!showFiltersAlways ? 'Show' : 'Hide'} Optional Filters{' '}
                        <SVGInline src={showFiltersAlways ? icons.arrowUpIcon : icons.arrowDownIcon} />
                    </OptionsToggle>
                </OptionSection>
            );
        };

        const hideFilters = _.get(DaznMethods, selectedMethod)?.hideFilters && !showFiltersAlways;
        methodFilters.forEach((filter, index) => {
            const key = `filter.${filter}`;
            const filterObj = _.get(methodParams, key, '');
            if (!filterObj) return;

            if (Array.isArray(filterObj)) {
                fields.push(...renderSelectFields(filterObj, filter, key, index, fields, hideFilters));
                return;
            }

            if (hideFilters) return;

            fields.push(
                <DialogTextField
                    key={`text-field-${index}`}
                    value={bodyParamsValues?.[key] || ''}
                    label={`Insert $${_.get(DAZNParamNames, filter, filter)}`}
                    placeholder={`Insert ${_.get(DAZNParamNames, filter, filter)}`}
                    onChange={(evt: any) => {
                        setBodyParamsValues((values: any) => ({ ...values, [key]: evt.target.value }));
                    }}
                    toolTipText={selectedMethod === DaznMethodKeys.DAZN_SEARCH ? 'Just a helper field for the title search' : undefined}
                    optional
                />
            );
        });

        return fields;
    };

    return (
        <>
            <DialogDropdownSingle
                value={methodOptions.find((source: any) => source.value === selectedMethod) || ''}
                options={methodOptions}
                placeholder={'Select Method'}
                onChange={(value: any) => {
                    setErrors(_.omit(errors, 'method'));
                    setSelectedMethod(value.value);
                    setQueryParamsValues({});
                    setBodyParamsValues({});
                }}
                error={errors.method}
                dataCy={'prime-method-source-field'}
            />
            {selectedMethod ? (
                <>
                    {renderStreamingRightsSelect()}
                    {bodyParamsValues?.[streamingRightsFilterKey] && (
                        <>
                            {renderSortField()}
                            {renderFilterFields()}
                        </>
                    )}
                </>
            ) : null}
        </>
    );
};

export default DaznSourceFields;
