import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useMatch, useParams } from 'react-router-dom';
import { useAppDispatch as useDispatch, useAppSelector } from '../hooks/redux';
import Login from './Login/Login';
import Projects from './Projects/Projects';
import NotFound from './common/NotFound/NotFound';
import Pages from './Pages/Pages';
import PageEdit from './PageEdit/PageEdit';
import Tenants from './Tenants/Tenants';
import { UsersAndGroups } from './UsersAndGroups/UsersAndGroups';
import { calculateIsProjectLead, calculateIsTenantAdmin, PermissionsState, setUserPermissions } from '../redux/slices/permissionsSlice';
import NoAccess from './common/NoAccess/NoAccess';
import { Languages } from './Languages/Languages';
import { ActiveItemState, setActiveProject, setActiveTenant, unsetActiveProject, unsetActiveTenant } from '../redux/slices/activeItemSlice';
import ConfigServiceAPI from '../utils/api/configServiceAPI';
import { AuthState } from '../redux/slices/authSlice';
import Modules from './Modules/Modules';
import { Items } from './Items/Items';
import Menus from './Menus/Menus';
import { GroupEdit } from './TargetGroups/GroupEdit';
import TargetGroups from './TargetGroups/TargetGroups';
import Settings from './Settings/Settings';
import StyleAndBranding from './StyleAndBranding/StyleAndBranding';
import { Dashboard } from './Dashboard/Dashboard';
import { Sources } from './Sources/Sources';
import FileManager from './FileManager/FileManager';
import { noProjectIdRoutes, PageRoutes, superadminRoutes } from '../types/RouteTypes';
import _ from 'lodash';
import { ProjectsState } from '../redux/slices/projectsSlice';
import { SearchPage } from './SearchPage/SearchPage';
import AudienceLibrary from './TargetConditions/AudienceLibrary/AudienceLibrary';
import { DisplayConditions } from './TargetConditions/DisplayConditions/DisplayConditions';
import { SuperadminGroups } from './Superadmin/Templates/Groups/Groups';
import DisabledProjects from './Superadmin/DisabledProjects/DisabledProjects';
import DisabledTenants from './Superadmin/DisabledTenants/DisabledTenants';
import { Branding } from './Superadmin/Branding/Branding';
import { Audiences as AudiencesTemplates } from './Superadmin/Templates/Groups/Audiences';
import { Modules as ModulesTemplates } from './Superadmin/Templates/Modules/Modules';
import { Sources as SourceTemplates } from './Superadmin/Templates/Sources/Sources';
import Translations from './Languages/Translations';
import { FeatureConfig } from './Superadmin/FeatureConfig/FeatureConfig';
import Aggregate from './Aggregate/Aggregate';
import { PageStylesTemplate } from './Superadmin/Templates/PageStyles/PageStylesTemplate';
import { itemsTemplates } from './Superadmin/Templates/Items/Items';
import { PagesTemplates } from './Superadmin/Templates/Pages/Pages';
import { PageTemplateEdit } from './Superadmin/Templates/Pages/PageEdit';
import UnpublishedChanges from './UnpublishedChanges/UnpublishedChanges';
import Analytics from './Analytics/Analytics';
import Monetize from './Monetize/Monetize';
import Personalize from './Personalize/Personalize';
import Optimize from './Optimize/Optimize';
import FeatureFlags from './FeatureFlags/FeatureFlags';
import AdSettings from './Monetize/AdSettings';
import { AssetManager } from './AssetManager/AssetManager';

type GuardedRouteProps = {
    component: any;
    path: string;
};

type Authorized = {
    authorized?: boolean;
    session?: boolean;
    noProjectId?: boolean;
};

const PageRoutesMapping = {
    [PageRoutes.LOGIN]: Login,
    [PageRoutes.PROJECTS]: Projects,
    [PageRoutes.TENANTS]: Tenants,
    [PageRoutes.DASHBOARD]: Dashboard,
    [PageRoutes.AGGREGATE]: Aggregate,
    [PageRoutes.ANALYZE]: Analytics,
    [PageRoutes.MONETIZE]: Monetize,
    [PageRoutes.AD_RULES]: AdSettings,
    [PageRoutes.PERSONALIZE]: Personalize,
    [PageRoutes.OPTIMIZE]: Optimize,
    [PageRoutes.PAGES]: Pages,
    [PageRoutes.MODULES]: Modules,
    [PageRoutes.ITEMS]: Items,
    [PageRoutes.MENUS]: Menus,
    [PageRoutes.SETTINGS]: Settings,
    [PageRoutes.FEATURE_FLAGS]: FeatureFlags,
    [PageRoutes.TARGET_GROUPS]: TargetGroups,
    [PageRoutes.NEW_PAGE]: PageEdit,
    [PageRoutes.PAGE]: PageEdit,
    [PageRoutes.LANGUAGES]: Languages,
    [PageRoutes.LANGUAGE_TRANSLATIONS]: Translations,
    [PageRoutes.USERS_GROUPS]: UsersAndGroups,
    [PageRoutes.TARGET_GROUP]: GroupEdit,
    [PageRoutes.TARGET_CONDITIONS]: DisplayConditions,
    [PageRoutes.AUDIENCES]: AudienceLibrary,
    [PageRoutes.PAGE_STYLES]: StyleAndBranding,
    [PageRoutes.SOURCES]: Sources,
    [PageRoutes.FILE_MANAGER]: FileManager,
    [PageRoutes.SEARCH]: SearchPage,
    [PageRoutes.ASSET_MANAGER]: AssetManager,
    [PageRoutes.SUPERADMIN_GROUPS]: SuperadminGroups,
    [PageRoutes.SUPERADMIN_TENANTS]: DisabledTenants,
    [PageRoutes.SUPERADMIN_PROJECTS]: DisabledProjects,
    [PageRoutes.SUPERADMIN_BRANDING]: Branding,
    [PageRoutes.SUPERADMIN_AUDIENCES]: AudiencesTemplates,
    [PageRoutes.SUPERADMIN_CONFIG]: FeatureConfig,
    [PageRoutes.SUPERADMIN_ITEMS]: itemsTemplates,
    [PageRoutes.SUPERADMIN_PAGES]: PagesTemplates,
    [PageRoutes.SUPERADMIN_PAGE]: PageTemplateEdit,
    [PageRoutes.SUPERADMIN_PAGE_STYLES]: PageStylesTemplate,
    [PageRoutes.SUPERADMIN_MODULES]: ModulesTemplates,
    [PageRoutes.SUPERADMIN_SOURCES]: SourceTemplates,
    [PageRoutes.UNPUBLISHED_CHANGES]: UnpublishedChanges
};
const PROJECT_BASE = '/projects/:project_id';

const GuardedRoute: React.FC<GuardedRouteProps> = ({ component: Component, path, ...rest }) => {
    const { loggedIn, sessionExpiresAt }: AuthState = useAppSelector((state) => state.auth);
    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);
    const { projects }: ProjectsState = useAppSelector((state) => state.projects);
    const { activeTenantId, activeProjectId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const [isAuthorized, setIsAuthorized] = useState<Authorized>({});

    const dispatch = useDispatch();
    const match = useMatch(path);

    useEffect(() => {
        const pathWithoutProjectId = `/${_.last(path.split('/'))}`;

        const checkIsAuthorized = async () => {
            const checkSession = () => {
                if (!loggedIn || !sessionExpiresAt) return false;
                return Date.now() < sessionExpiresAt;
            };

            const checkProjectId = () => {
                // If a user's permission to an activeProject is revoked, unset the state, so it gets redirected to projectSelection page
                if (
                    activeProjectId &&
                    activeTenantId &&
                    userPermissions &&
                    !userPermissions.isSuperAdmin &&
                    !calculateIsProjectLead(activeProjectId, userPermissions) &&
                    !calculateIsTenantAdmin(activeTenantId, userPermissions) &&
                    !userPermissions.projectIds.includes(activeProjectId)
                ) {
                    dispatch(unsetActiveProject());
                    dispatch(unsetActiveTenant());
                }

                if (superadminRoutes.includes(path as any)) return true;

                switch (pathWithoutProjectId) {
                    case PageRoutes.DASHBOARD:
                    case PageRoutes.LANGUAGES:
                    case PageRoutes.PAGES:
                    case PageRoutes.ITEMS:
                    case PageRoutes.MODULES:
                    case PageRoutes.MENUS:
                    case PageRoutes.TARGET_GROUPS:
                    case PageRoutes.SETTINGS:
                    case PageRoutes.FILE_MANAGER:
                    case PageRoutes.SOURCES:
                    case PageRoutes.PAGE_STYLES:
                    case PageRoutes.TARGET_CONDITIONS:
                    case PageRoutes.UNPUBLISHED_CHANGES:
                        return activeProjectId;
                    default:
                        return true;
                }
            };
            const checkPath = async () => {
                let permissions = { ...userPermissions };
                if (!userPermissions) {
                    const response = await ConfigServiceAPI.getUserInfo(true);
                    if (response.permissions) {
                        dispatch(setUserPermissions(response.permissions));
                        permissions = { ...response.permissions };
                    }
                }
                // more paths to be added
                switch (path) {
                    case PageRoutes.TENANTS:
                    case PageRoutes.SUPERADMIN_GROUPS:
                    case PageRoutes.SUPERADMIN_MODULES:
                    case PageRoutes.SUPERADMIN_BRANDING:
                    case PageRoutes.SUPERADMIN_TENANTS:
                    case PageRoutes.SUPERADMIN_PROJECTS:
                    case PageRoutes.SUPERADMIN_AUDIENCES:
                    case PageRoutes.SUPERADMIN_PAGE_STYLES:
                    case PageRoutes.SUPERADMIN_CONFIG:
                    case PageRoutes.SUPERADMIN_ITEMS:
                    case PageRoutes.SUPERADMIN_PAGES:
                    case PageRoutes.SUPERADMIN_PAGE:
                    case PageRoutes.SUPERADMIN_SOURCES:
                        return permissions?.isSuperAdmin === true;
                    default:
                        return true;
                }
            };

            const isAuthorized: Authorized = {
                authorized: true
            };

            if (!checkSession()) {
                isAuthorized.authorized = false;
                isAuthorized.session = true;
            } else if (!(await checkPath())) {
                isAuthorized.authorized = false;
            } else if (!checkProjectId()) {
                isAuthorized.authorized = false;
                isAuthorized.noProjectId = true;
            }
            setIsAuthorized(isAuthorized);
        };
        checkIsAuthorized();
    }, [path, userPermissions]);

    const checkProjectIdPath = (url: string, params: any) => {
        const path = _.last(url.split('/'));
        if (noProjectIdRoutes.includes(`/${path}` as PageRoutes)) return;
        const id = params?.project_id;
        if (projects.length) {
            const activeProject = projects.find((project) => project._id === id);
            activeTenantId !== activeProject?.tenantId && dispatch(setActiveTenant(activeProject?.tenantId));
        }
        if (activeProjectId === id) return;

        dispatch(setActiveProject(id));
    };

    if (!Object.keys(isAuthorized).length) {
        return <div></div>;
    }
    const { authorized, session, noProjectId } = isAuthorized;

    const component = () => {
        checkProjectIdPath(path, match?.params);

        if (authorized) {
            return path === PageRoutes.LOGIN ? <Navigate to={PageRoutes.PROJECTS} /> : <Component />;
        }

        if (path === PageRoutes.LOGIN) {
            return <Component />;
        }

        return session ? (
            <Navigate to={PageRoutes.LOGIN} />
        ) : noProjectId ? (
            <Navigate to={PageRoutes.PROJECTS} />
        ) : (
            <Navigate to={PageRoutes.NO_ACCESS} />
        );
    };

    return component();
};

export default function AppRoutes() {
    const { activeProjectId } = useAppSelector((state) => state.activeItem);

    const { project_id } = useParams();

    return (
        <Routes>
            <Route
                path={'/'}
                element={
                    activeProjectId ? (
                        <Navigate
                            to={{
                                ...location,
                                pathname: `${PROJECT_BASE.replace(':project_id', activeProjectId)}${PageRoutes.DASHBOARD}`
                            }}
                        />
                    ) : (
                        <Navigate to={{ ...location, pathname: PageRoutes.PROJECTS }} />
                    )
                }
            />

            <Route
                path={PROJECT_BASE}
                element={
                    <Navigate
                        to={{ ...location, pathname: `${PROJECT_BASE.replace(':project_id', project_id || '')}${PageRoutes.DASHBOARD}` }}
                    />
                }
            />
            {(Object.keys(PageRoutesMapping) as Array<keyof typeof PageRoutesMapping>).map((route, index) => {
                const path = noProjectIdRoutes.includes(route) ? route : `${PROJECT_BASE}${route}`;
                return (
                    <Route
                        key={index}
                        path={path}
                        element={<GuardedRoute key={index} path={path} component={PageRoutesMapping[route]} />}
                    />
                );
            })}

            <Route path={PageRoutes.NO_ACCESS} element={<NoAccess />} />
            <Route element={<NotFound />} />
        </Routes>
    );
}
