export type AdSetting = {
    _id: string;
    type: ADTypes;
    name: string;
    tenantId: string;
    projectId: string;
    conditionIds: string[];
    adUnit: string;
    adUnitCode: string;
    networkCode: string;
    adFormat: string[];
    contentFormat?: string[];
    modifiedBy?: string;
    conditions?: any[];
    lastModified?: number;
    modifiedByUser?: {
        name: string;
        icon?: string;
    };
};

export const AD_TYPES = {
    CONTENT: 'CONTENT',
    FORMAT: 'FORMAT'
} as const;

export type ADTypes = typeof AD_TYPES[keyof typeof AD_TYPES];
