import { FC, useEffect, useState } from 'react';
import GenericDialog, {
    DialogButton,
    DialogDropdownMultiple,
    DialogDropdownSingle,
    DialogTextField,
    DialogTypes
} from '../../common/Dialog/GenericDialog';
import { TargetConditionsSelect } from '../../common/Select/TargetConditionsSelect';
import { OptionSection, OptionsToggle } from '../../Modules/Dialogs/NewModule.css';
import icons from '../../../style';
import SVGInline from 'react-inlinesvg';
import { ADTypes, AD_TYPES, AdSetting } from '../../../types/AdSettings';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { useAppSelector } from '../../../hooks/redux';
import { validator } from '../../../utils/fnValidator';
import { DROPDOWN_GROUP_LABEL_VALUE } from '../../../utils/Globals';
import { getOptionsIndexes } from '../../../utils/fnDialogs';
import _ from 'lodash';
import { DropdownLabel } from '../../../style/styled-components/reusable.css';
import { SourcesState } from '../../../redux/slices/sourceSlice';
import { AdSettingsState } from '../../../redux/slices/adSettingsSlice';

type NewAdSettingsDialogProps = {
    open: boolean;
    type: ADTypes;
    adSetting?: AdSetting;
    adUnitOptions?: { value: string; label: JSX.Element }[];
    onSave: (adSettings: AdSetting) => void;
    onClose: () => void;
};

enum ContentFormatSections {
    LIVE_TV_CHANNELS = 'LIVE TV Channels',
    SERVICES = 'Services'
}

const NO_ADS_LABEL = 'No Ads';

const adFormats: string[] = [
    'VOD Pre-Roll',
    'VOD Mid-Roll',
    'VOD Post-Roll',
    'LIVE TV Pre-Roll',
    'LIVE TV Channel Switch',
    'LIVE TV Post-Roll'
];
const tv_channels_content: string[] = ['ARD', 'ZDF', 'ARTE', 'BBC News'];
const resources_content: string[] = [];

export const NewAdSettingsDialog: FC<NewAdSettingsDialogProps> = ({ open, type, adSetting, onClose, onSave, adUnitOptions }) => {
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const { services, loading: servicesLoading }: SourcesState = useAppSelector((state) => state.dynamicSources);
    const { adUnits }: AdSettingsState = useAppSelector((state) => state.adSettings);

    const [name, setName] = useState<string>('');
    const [adFormat, setAdFormat] = useState<string[]>([]);
    const [contentFormatOptions, setContentFormatOptions] = useState<{ label: string | JSX.Element; value: string }[]>([]);

    const [adUnit, setAdUnit] = useState<string>('');
    const [contentFormat, setContentFormat] = useState<string[]>([]);
    const [conditionIds, setConditionIds] = useState<string[]>([]);
    const [optionsExpanded, setOptionsExpanded] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ name?: string; adFormat?: string; adUnit?: string; contentFormat?: string }>({});

    const handleOnClose = () => {
        onClose();

        setName('');
        setAdFormat([]);
        setContentFormat([]);
        setAdUnit('');
        setConditionIds([]);
        setErrors({});
    };

    const adFormatsPerType = type === AD_TYPES.CONTENT ? [...adFormats, NO_ADS_LABEL] : adFormats;

    const adFormatOptions = adFormatsPerType.map((ad) => ({
        value: ad,
        label: ad
    }));

    useEffect(() => {
        const formatOptions: any[] = [];
        Object.values(ContentFormatSections).forEach((section) => {
            const subsectionOptions =
                section === ContentFormatSections.SERVICES
                    ? services.map((service) => ({
                          label: service.title,
                          value: service.key
                      }))
                    : tv_channels_content.map((channel) => ({
                          label: channel,
                          value: channel
                      }));

            formatOptions.push({
                label: (
                    <DropdownLabel>
                        <span>{section}</span>
                    </DropdownLabel>
                ),
                value: DROPDOWN_GROUP_LABEL_VALUE
            });

            formatOptions.push(...subsectionOptions);
        });

        setContentFormatOptions(formatOptions);
    }, [services]);

    useEffect(() => {
        if (!adSetting) return;
        setName(adSetting.name);
        setAdUnit(adSetting.adUnit);
        setAdFormat(adSetting.adFormat);
        setContentFormat(adSetting.contentFormat || []);
        setConditionIds(adSetting.conditionIds);
    }, [adSetting]);

    const validateAdSettings = () => {
        const newErrors = { ...errors };
        newErrors.name = validator({ required: true }, name);
        newErrors.adFormat = validator({ required: true }, adFormat);

        if (!adFormat.includes(NO_ADS_LABEL)) {
            newErrors.adUnit = validator({ required: true }, adUnit);
        }

        if (type === AD_TYPES.CONTENT) {
            newErrors.contentFormat = validator({ required: true }, contentFormat);
        }

        setErrors(newErrors);
        return !Object.values(newErrors).filter((value) => !!value).length;
    };

    const handleOnSave = () => {
        if (!validateAdSettings()) return;

        const fullAdUnit = adUnits.find((ad) => ad.id === adUnit);

        const newAdSettings: AdSetting = {
            _id: adSetting?._id || '',
            tenantId: activeTenantId || '',
            projectId: activeProjectId || '',
            type,
            name,
            adFormat,
            adUnit,
            adUnitCode: fullAdUnit?.adUnitCode || '',
            networkCode: fullAdUnit?.networkCode?.toString() || '',
            conditionIds,
            lastModified: adSetting?.lastModified
        };

        if (type === AD_TYPES.CONTENT) {
            newAdSettings.contentFormat = contentFormat;
        }

        onSave(newAdSettings);
        handleOnClose();
    };

    const renderOptions = () => {};

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: handleOnSave
    };
    const closeButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: handleOnClose
    };

    if (!open) return null;

    return (
        <GenericDialog type={DialogTypes.Form} title={'Create Settings'} actionButtons={[closeButton, saveButton]} onClose={handleOnClose}>
            <DialogTextField
                value={name}
                onChange={(e: any) => {
                    setName(e.target.value);
                    setErrors(_.omit(errors, 'name'));
                }}
                placeholder={'Name'}
                label={'Name'}
                error={errors.name}
            />

            {type === AD_TYPES.CONTENT && (
                <DialogDropdownMultiple
                    options={contentFormatOptions}
                    value={contentFormatOptions.filter((opt) => contentFormat.includes(opt.value))}
                    onChange={(value: any) => {
                        setContentFormat(value.map((v: any) => v.value));
                        setErrors(_.omit(errors, 'contentFormat'));
                    }}
                    placeholder={'Content Format'}
                    unclickableIndexes={getOptionsIndexes(contentFormatOptions, [DROPDOWN_GROUP_LABEL_VALUE])}
                    error={errors.contentFormat}
                    labeledSelect
                    allowSelectAll
                    isDisabled={servicesLoading}
                />
            )}
            <DialogDropdownMultiple
                options={adFormatOptions}
                value={adFormatOptions.filter((opt) => adFormat.includes(opt.value))}
                onChange={(value: any) => {
                    if (value?.some((val: any) => val.value === NO_ADS_LABEL)) {
                        setAdUnit('');
                        !adFormat.length || adFormat.includes(NO_ADS_LABEL)
                            ? setAdFormat([NO_ADS_LABEL])
                            : setAdFormat(value.map((v: any) => v.value).filter((v: any) => v !== NO_ADS_LABEL));
                    } else {
                        setAdFormat(value.map((v: any) => v.value));
                    }

                    setErrors(_.omit(errors, 'adFormat'));
                }}
                placeholder={'Ad Format'}
                error={errors.adFormat}
                allowSelectAll
            />
            <DialogDropdownSingle
                options={adUnitOptions || []}
                value={adUnitOptions?.find((opt) => opt.value === adUnit) || ''}
                onChange={(value: any) => {
                    setAdUnit(value.value);
                    setErrors(_.omit(errors, 'adUnit'));
                }}
                placeholder={'Ad Unit'}
                error={errors.adUnit}
                isDisabled={adFormat.includes(NO_ADS_LABEL)}
            />
            <TargetConditionsSelect
                targets={conditionIds}
                onChange={(val) => {
                    setConditionIds(val);
                }}
                inDialog
            />

            <OptionSection>
                <OptionsToggle onClick={() => setOptionsExpanded(!optionsExpanded)}>
                    Show Additional Filters <SVGInline src={optionsExpanded ? icons.arrowUpIcon : icons.arrowDownIcon} />
                </OptionsToggle>
                {optionsExpanded && renderOptions()}
            </OptionSection>
        </GenericDialog>
    );
};
