import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type SidebarState = {
    sidebarOpen: boolean;
    subsectionOpen: string;
    engageSidebarOpen: string;
    groupKeyToExpandOnSidebarReopen: string;
};

export const initialState: SidebarState = {
    sidebarOpen: true,
    subsectionOpen: '',
    engageSidebarOpen: '',
    groupKeyToExpandOnSidebarReopen: ''
};

const slice = createSlice({
    name: 'sidebarOpen',
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            state.sidebarOpen = !state.sidebarOpen;
        },
        toggleEngageSidebar: (state, action) => {
            state.engageSidebarOpen = action.payload.expandedGroup;
        },
        setGroupKeyToExpandOnSidebarReopen: (state, action) => {
            state.groupKeyToExpandOnSidebarReopen = action.payload.groupKeyToSave;
        },
        setSubsectionOpen: (state, action: PayloadAction<string>) => {
            state.subsectionOpen = action.payload;
        },
        closeSideBar: (state) => {
            state.sidebarOpen = false;
        },
        openSideBar: (state) => {
            state.sidebarOpen = true;
        }
    }
});

export const { toggleSidebar, closeSideBar, openSideBar, toggleEngageSidebar, setSubsectionOpen, setGroupKeyToExpandOnSidebarReopen } =
    slice.actions;
export default slice.reducer;
