import _, { rest } from 'lodash';
import React, { useEffect, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import icons from '../../assets/images/icons';
import { useAppSelector } from '../../hooks/redux';
import { ApplicationWrapper, DatepickerBackdrop, MainContentWrapper } from '../../style/styled-components/reusable.css';
import { DIALOG_NAMES, dialogAlert } from '../../utils/fnDialogs';
import ScreenTitle from '../common/DashboardTitle/ScreenTitle';
import { DatePickerComponent as DatePicker } from '../common/DatePicker/DatePicker';
import { DialogDropdownMultiple } from '../common/Dialog/GenericDialog';
import { Loader } from '../common/Loader/Loader';
import Sidebar from '../common/Sidebar/Sidebar';
import { renderTooltip, tooltipTypes } from '../common/Tooltips/Tooltips';
import {
    CardValue,
    DatePickerWrapper,
    Delimiter,
    DifferenceContainer,
    FiltersWrapper,
    FilterText,
    FilterToggle,
    IngishtsWidgetsContainer,
    InsightsDatesContainer,
    InsightsWidget,
    MetricLink,
    MetricTitle,
    MetricValue,
    NPAWButton
} from './Optimize.css';
import {
    AnalyticsOptions,
    getAnalyticsData,
    youboraCountryFilters,
    youboraDeviceFilters,
    youboraHandlerResponse,
    youboraMetrics
} from '../../utils/fnPreviews';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../common/HelpIcon/HelpIcon';
import { Capabilities, CapabilitiesScreen } from '../Capabilities/CapabilitiesTopScreen';
import useRestrictedProjectAccess from '../../hooks/useRestrictedAccess';
import { restrictedSectionsTypes } from '../../types/Project';
import { ProjectsState } from '../../redux/slices/projectsSlice';

export const insightsWidgets = {
    happiness: 'Overall User Happiness',
    joinTime: 'Average Join Time / Buffer Ratio',
    views: 'Number of plays',
    playTime: 'Average Playtime',
    errors: 'Plays / Errors',
    subscribers: 'Subscribers / Abandoned Users'
};

type Filters = 'COUNTRY' | 'DEVICE';

const Insights: React.FC = () => {
    const { isRestrictedArea: isInsightsRestricted } = useRestrictedProjectAccess(restrictedSectionsTypes.CAPABILITIES, 'optimize');
    const { loading: projectsLoading }: ProjectsState = useAppSelector((state) => state.projects);

    const oneWeekAgo = new Date().setUTCHours(0, 0, 0, 0) - 7 * 24 * 3600 * 1000;
    const twoWeeksAgo = new Date().setUTCHours(0, 0, 0, 0) - 14 * 24 * 3600 * 1000;
    const today = new Date().setUTCHours(0, 0, 0, 0);

    const [datePickerOpen, setDatePickerOpen] = useState<{ datePicker1?: boolean; datePicker2?: boolean }>({});
    const [loading, setLoading] = useState(false);
    const [currentData, setCurrentData] = useState<youboraHandlerResponse>({});
    const [specificData, setSpecificData] = useState<youboraHandlerResponse>({});
    const [dataInterval, setDataInterval] = useState<{ startDate?: number; endDate?: number }>({});
    const [comparingDataInterval, setComparingDataInterval] = useState<{ startDate?: number; endDate?: number }>({});
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [countryFilterOptions, setCountryFilterOptions] = useState<{ value?: string; label?: string }[]>([]);
    const [deviceFilterOptions, setdeviceFilterOptions] = useState<{ value?: string; label?: string }[]>([]);
    const [filterValues, setFilterValues] = useState<{ [key in Filters]?: string[] }>({});
    const [accountCode, setAccountCode] = useState<string>('');

    const { activeProjectId } = useAppSelector((state) => state.activeItem);
    const linkURL = `https://suite.npaw.com/v/${accountCode}/analytics/overview`;

    const loadCurrentData = async (filter?: any) => {
        let fromDate = oneWeekAgo;

        return await getAnalyticsData(AnalyticsOptions.YOUBORA, Object.values(youboraMetrics), fromDate, today, filter);
    };

    const loadSpecificData = async (startDate?: number, endDate?: number, filter?: any) => {
        return await getAnalyticsData(
            AnalyticsOptions.YOUBORA,
            Object.values(youboraMetrics),
            startDate || dataInterval.startDate,
            endDate || dataInterval.endDate,
            filter
        );
    };

    useEffect(() => {
        setCountryFilterOptions(Object.values(youboraCountryFilters).map((filter) => ({ label: filter, value: filter })));
        setdeviceFilterOptions(Object.values(youboraDeviceFilters).map((filter) => ({ label: filter, value: filter })));
    }, []);

    useEffect(() => {
        let currentData = {};
        setLoading(true);
        loadCurrentData()
            .then((data) => {
                currentData = data;
                // needed to create the url for npaw based on the account
                if (data?.accountCode) {
                    setAccountCode(data.accountCode);
                }
                return loadSpecificData(twoWeeksAgo, oneWeekAgo);
            })
            .then((data) => {
                setCurrentData(currentData);
                setSpecificData(data);
                setLoading(false);
            });
    }, [activeProjectId]);

    useEffect(() => {
        const fetchData = async () => {
            const filter = !_.isEmpty(filterValues) ? filterValues : undefined;
            const emptySpecificInterval = _.isEmpty(dataInterval);
            const emptyComparingInterval = _.isEmpty(comparingDataInterval);
            if (
                (!dataInterval.startDate || !dataInterval.endDate) &&
                (!comparingDataInterval.startDate || !comparingDataInterval.endDate) &&
                !filter
            )
                return;
            let specData: youboraHandlerResponse = {};
            let currData: youboraHandlerResponse = {};

            setLoading(true);

            // it should handle the case when we filter data without selecting anything from the data interval
            if (filter && emptySpecificInterval && emptyComparingInterval) {
                specData = await loadSpecificData(twoWeeksAgo, oneWeekAgo, filter);
                currData = await loadCurrentData(filter);
                setSpecificData(specData);
                setCurrentData(currData);
                setLoading(false);
            } else {
                // if we select only the comparing interval, the specific data should be already populated with the last week data
                specData = emptySpecificInterval ? { ...specificData } : await loadSpecificData(undefined, undefined, filter);

                //in case the comparing interval is not populated, after loading specific data,  the currentData will be the one iit's already loaded,
                //  otherwise  set the current data based on the comparing interval.
                currData = emptyComparingInterval
                    ? { ...currentData }
                    : await loadSpecificData(comparingDataInterval.startDate, comparingDataInterval.endDate, filter);

                !emptySpecificInterval && setSpecificData(specData);
                setCurrentData(currData);
                setLoading(false);
            }

            if (!Object.values(currData).filter((val) => val).length || !Object.values(specData).filter((val) => val).length) {
                // if either data does not contain values, we show a warning
                dialogAlert(DIALOG_NAMES.INGISHTS_DATA_WARNING);
            }
        };

        fetchData();
    }, [dataInterval, comparingDataInterval, filterValues]);

    const handleDatePick = (value: any) => {
        const comparingInterval = datePickerOpen.datePicker2;
        if (comparingInterval) {
            const startCurrentDate = value?.startDate * 1000;
            const endCurrentDate = value?.endDate * 1000;
            setComparingDataInterval({ startDate: startCurrentDate, endDate: endCurrentDate });
            return;
        }
        const newStartDate = (value?.startDate || dataInterval.startDate) * 1000;
        const newEndDate = (value?.endDate || dataInterval.endDate) * 1000;
        setDataInterval({ startDate: newStartDate, endDate: newEndDate });
        setComparingDataInterval({});
    };

    const renderDatePickerSection = () => {
        return (
            <>
                <InsightsDatesContainer>
                    <DatePickerWrapper>
                        <DatePicker
                            isOpen={!!datePickerOpen.datePicker1}
                            toggleOpenDialog={(val) => setDatePickerOpen({ datePicker1: val })}
                            saveDate={(val) => handleDatePick(val)}
                            eDate={(dataInterval.endDate || oneWeekAgo) / 1000}
                            sDate={(dataInterval.startDate || twoWeeksAgo) / 1000}
                            filterDate={(date) => {
                                return new Date() > date;
                            }}
                            customLabel={'For selected timeframe'}
                            customHeaderText={'Choose past Timeframe'}
                            noTooltip
                            isRange
                        />
                        <Delimiter>-</Delimiter>
                        <DatePicker
                            isOpen={!!datePickerOpen.datePicker2}
                            toggleOpenDialog={(val) => setDatePickerOpen({ datePicker2: val })}
                            saveDate={(val) => handleDatePick(val)}
                            eDate={(comparingDataInterval.endDate || today) / 1000}
                            sDate={(comparingDataInterval.startDate || oneWeekAgo) / 1000}
                            filterDate={(date) => {
                                return new Date() > date;
                            }}
                            customHeaderText={'Compare with Timeframe'}
                            noTooltip
                            isRange
                        />
                    </DatePickerWrapper>

                    <NPAWButton
                        onClick={() => {
                            if (!accountCode) return;
                            window.open(linkURL, '_blank', 'noopener,noreferrer');
                        }}
                    >
                        <SVGInline src={icons.npawIcon} />
                        <span>Open NPAW Dashboard</span>
                    </NPAWButton>
                </InsightsDatesContainer>
                <FilterToggle onClick={() => setShowFilters(!showFilters)}>
                    Optional Filters <SVGInline src={showFilters ? icons.arrowUpIcon : icons.arrowDownIcon} />
                </FilterToggle>

                {showFilters && (
                    <FiltersWrapper>
                        <FilterText>Filter By</FilterText>
                        <DialogDropdownMultiple
                            options={countryFilterOptions}
                            value={countryFilterOptions.filter((opt) => filterValues['COUNTRY']?.includes(opt?.value || ''))}
                            placeholder={'Country'}
                            onChange={(value: any) => {
                                const newValues = { ...filterValues };
                                newValues.COUNTRY = [...value.map((v: any) => v.value)];
                                setFilterValues(newValues);
                            }}
                            noLabel
                            maxLength={1}
                        />
                        <DialogDropdownMultiple
                            options={deviceFilterOptions}
                            value={deviceFilterOptions.filter((opt) => filterValues['DEVICE']?.includes(opt?.value || ''))}
                            placeholder={'Device'}
                            onChange={(value: any) => {
                                const newValues = { ...filterValues };
                                newValues.DEVICE = [...value.map((v: any) => v.value)];
                                setFilterValues(newValues);
                            }}
                            maxLength={1}
                            noLabel
                        />
                    </FiltersWrapper>
                )}
            </>
        );
    };

    const renderWidgets = () => {
        const { avgHappiness, joinTime, bufferRatio, playtime, playsVsErrors, subscribers, abandonUsers, views } = currentData;
        const renderComparison =
            !!Object.values(specificData).filter((val) => val).length && !!Object.values(currentData).filter((val) => val).length;

        const values: any = {};
        const tooltips: any = {};

        values.happiness = avgHappiness?.toFixed(1) ?? 'Data unavailable';
        values.joinTime =
            joinTime !== undefined && bufferRatio !== undefined ? `${joinTime.toFixed(2)} / ${bufferRatio.toFixed(2)}` : 'Data unavailable';
        values.views = views !== undefined ? `${views}` : 'Data unavailable';
        values.playtime = playtime !== undefined ? `${Math.floor(playtime / 60)}m ${Math.floor(playtime % 60)}s` : 'Data unavailable';
        values.errors = playsVsErrors !== undefined ? `1 error every ${playsVsErrors.toFixed(2)} plays` : 'Data unavailable';
        values.subscribers =
            subscribers !== undefined && abandonUsers !== undefined ? `${subscribers} / ${abandonUsers}` : 'Data unavailable';

        if (renderComparison) {
            const computeTooltipText = (val1: number, val2: number, difference: string, isLower: boolean, digits = 1) => {
                const firstDateString =
                    new Date(dataInterval.startDate || twoWeeksAgo).toLocaleDateString() +
                    ' - ' +
                    new Date(dataInterval.endDate || oneWeekAgo).toLocaleDateString();
                const secondDateString =
                    new Date(comparingDataInterval.startDate || oneWeekAgo).toLocaleDateString() +
                    ' - ' +
                    new Date(comparingDataInterval.endDate || today).toLocaleDateString();
                const comparingIsPast = (comparingDataInterval.endDate || today) < today;
                const statement = comparingIsPast ? 'was' : 'is';
                return (
                    <>
                        <div>
                            Value for {firstDateString} was: {val1.toFixed(digits)}
                        </div>
                        <div>
                            Value for {secondDateString} {statement}: {val2.toFixed(digits)}
                        </div>
                        <div>
                            The value {!isLower ? 'increased' : 'decreased'} by {difference}
                        </div>
                    </>
                );
            };

            if (avgHappiness !== undefined && specificData.avgHappiness !== undefined) {
                const isLower = avgHappiness < specificData.avgHappiness;
                const diff = Math.abs(avgHappiness - specificData.avgHappiness).toFixed(1);
                const difference = diff === 'Infinity' ? 'N/A' : diff;
                tooltips.happiness = computeTooltipText(specificData.avgHappiness, avgHappiness, difference, isLower);
                values.happiness = (
                    <>
                        <CardValue>{avgHappiness.toFixed(1)}</CardValue>
                        <DifferenceContainer>
                            <span>
                                {isLower ? '-' : '+'} {difference}
                            </span>
                            {avgHappiness !== specificData.avgHappiness && (
                                <SVGInline src={isLower ? icons.analyticsDownIcon : icons.analyticsUpIcon} />
                            )}
                        </DifferenceContainer>
                    </>
                );
            }
            if (
                joinTime !== undefined &&
                bufferRatio !== undefined &&
                specificData.joinTime !== undefined &&
                specificData.bufferRatio !== undefined
            ) {
                const valuesToCompare = [joinTime / bufferRatio, specificData.joinTime / specificData.bufferRatio];
                const isLower = valuesToCompare[0] < valuesToCompare[1];
                const diff = Math.abs(valuesToCompare[0] - valuesToCompare[1]).toFixed(2);
                const difference = diff === 'Infinity' ? 'N/A' : diff;
                tooltips.joinTime = computeTooltipText(valuesToCompare[1], valuesToCompare[0], difference, isLower, 2);
                values.joinTime = (
                    <>
                        <CardValue>{`${joinTime.toFixed(2)} / ${bufferRatio.toFixed(2)}`}</CardValue>
                        <DifferenceContainer>
                            <span>
                                {isLower ? '-' : '+'} {difference}
                            </span>
                            {valuesToCompare[0] !== valuesToCompare[1] && (
                                <SVGInline src={isLower ? icons.analyticsDownIcon : icons.analyticsUpIcon} />
                            )}
                        </DifferenceContainer>
                    </>
                );
            }
            if (views !== undefined && specificData.views !== undefined) {
                const isLower = views < specificData.views;
                const diff = Math.abs(views - specificData.views).toFixed(1);
                const difference = diff === 'Infinity' ? 'N/A' : diff;
                tooltips.views = computeTooltipText(specificData.views, views, difference, isLower, 1);
                values.views = (
                    <>
                        <CardValue>{views}</CardValue>
                        <DifferenceContainer>
                            <span>
                                {isLower ? '-' : '+'} {difference}
                            </span>
                            {views !== specificData.views && <SVGInline src={isLower ? icons.analyticsDownIcon : icons.analyticsUpIcon} />}
                        </DifferenceContainer>
                    </>
                );
            }
            if (playtime !== undefined && specificData.playtime !== undefined) {
                const isLower = playtime < specificData.playtime;
                const difference = Math.abs(playtime - specificData.playtime);
                const differenceText = `${Math.floor(difference / 60)}m ${Math.floor(difference % 60)}s`;
                const firstDateString =
                    new Date(dataInterval.startDate || twoWeeksAgo).toLocaleDateString() +
                    ' - ' +
                    new Date(dataInterval.endDate || oneWeekAgo).toLocaleDateString();
                const secondDateString =
                    new Date(comparingDataInterval.startDate || oneWeekAgo).toLocaleDateString() +
                    ' - ' +
                    new Date(comparingDataInterval.endDate || today).toLocaleDateString();
                const comparingIsPast = (comparingDataInterval.endDate || today) < today;
                const statement = comparingIsPast ? 'was' : 'is';
                tooltips.playTime = (
                    <>
                        <div>
                            Value for {firstDateString} was: {Math.floor(specificData.playtime / 60)}m{' '}
                            {Math.floor(specificData.playtime % 60)}s
                        </div>
                        <div>
                            Value for {secondDateString} {statement}: {Math.floor(playtime / 60)}m {Math.floor(playtime % 60)}s
                        </div>
                        <div>
                            The value {!isLower ? 'increased' : 'decreased'} by {differenceText}
                        </div>
                    </>
                );
                values.playTime = (
                    <>
                        <CardValue>{`${Math.floor(playtime / 60)}m ${Math.floor(playtime % 60)}s`}</CardValue>
                        <DifferenceContainer>
                            <span>{differenceText}</span>
                            {playtime !== specificData.playtime && (
                                <SVGInline src={isLower ? icons.analyticsDownIcon : icons.analyticsUpIcon} />
                            )}
                        </DifferenceContainer>
                    </>
                );
            }
            if (playsVsErrors !== undefined && specificData.playsVsErrors !== undefined) {
                const isLower = playsVsErrors < specificData.playsVsErrors;
                const diff = Math.abs(playsVsErrors - specificData.playsVsErrors).toFixed(2);
                const difference = diff === 'Infinity' ? 'N/A' : diff;
                tooltips.errors = computeTooltipText(specificData.playsVsErrors, playsVsErrors, difference, isLower, 2);
                values.errors = (
                    <>
                        <CardValue>{`1 error every ${playsVsErrors.toFixed(2)} plays`}</CardValue>
                        <DifferenceContainer $reverseColors $isLower={isLower}>
                            <span>
                                {isLower ? '-' : '+'} {difference}
                            </span>
                            {playsVsErrors !== specificData.playsVsErrors && (
                                <SVGInline src={isLower ? icons.analyticsDownIcon : icons.analyticsUpIcon} />
                            )}
                        </DifferenceContainer>
                    </>
                );
            }
            if (
                subscribers !== undefined &&
                abandonUsers !== undefined &&
                specificData.subscribers !== undefined &&
                specificData.abandonUsers !== undefined
            ) {
                const valuesToCompare = [subscribers / abandonUsers, specificData.subscribers / specificData.abandonUsers];
                const isLower = valuesToCompare[0] < valuesToCompare[1];
                const diff = Math.abs(valuesToCompare[0] - valuesToCompare[1]).toFixed(2);
                const difference = diff === 'Infinity' ? '999' : diff;
                tooltips.subscribers = computeTooltipText(valuesToCompare[1], valuesToCompare[0], difference, isLower, 2);
                values.subscribers = (
                    <>
                        <CardValue>{`${subscribers} / ${abandonUsers}`}</CardValue>
                        <DifferenceContainer>
                            <span>
                                {isLower ? '-' : '+'} {difference}
                            </span>
                            {valuesToCompare[0] !== valuesToCompare[1] && (
                                <SVGInline src={isLower ? icons.analyticsDownIcon : icons.analyticsUpIcon} />
                            )}
                        </DifferenceContainer>
                    </>
                );
            }
        }

        const widgets = (Object.keys(insightsWidgets) as Array<keyof typeof insightsWidgets>).map((key, index) => {
            return (
                <InsightsWidget key={`widget_${index}`}>
                    <MetricTitle>{insightsWidgets[key]}</MetricTitle>
                    {renderTooltip(<MetricValue>{values[key]}</MetricValue>, tooltipTypes.HTML, tooltips[key])}
                    <MetricLink>
                        <a href={linkURL} target="_blank" rel="noreferrer">
                            Check in NPAW
                        </a>
                    </MetricLink>
                </InsightsWidget>
            );
        });

        return <IngishtsWidgetsContainer>{widgets}</IngishtsWidgetsContainer>;
    };

    const generalLoading = loading || projectsLoading;

    return (
        <>
            {Object.values(datePickerOpen).some((value) => value) && <DatepickerBackdrop />}
            <ApplicationWrapper>
                <Sidebar />
                <MainContentWrapper>
                    <ScreenTitle
                        title="Optimize"
                        withProfile
                        circlesSlugOptions={{ default: CIRCLE_SLUGS.insights, onboarding: ONBOARDING_CIRCLE_SLUGS.insights }}
                    />
                    <CapabilitiesScreen type={Capabilities.OPTIMIZE} disabled={isInsightsRestricted} />
                    {!isInsightsRestricted && (
                        <>
                            {renderDatePickerSection()}
                            {generalLoading ? <Loader title={'Optimize'} /> : renderWidgets()}
                        </>
                    )}
                </MainContentWrapper>
            </ApplicationWrapper>
        </>
    );
};

export default Insights;
